<template>
    <div id="slideshow" class="carousel" data-bs-ride="carousel" :class="(card.editMode)?'carousel':'carousel slide'">
      <div class="carousel-indicators">
        <button id="slideshowBackButton" type="button" data-bs-target="#slideshow" data-bs-slide-to="0" class="active" aria-current="true"
                aria-label="Upload new image" v-show="card.editMode"></button>
        <button type="button" data-bs-target="#slideshow" v-for="(image, key) in section.file"
                :data-bs-slide-to="slideshowIndex(key)" :aria-label="image.systemFileName"
                :key="image.systemFileName"></button>
      </div>

      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active" v-if="card.editMode">
          <input type="file" ref="imguplodad" id="imgupload" style="display:none" @change="handleFileUpload($event)"/>
          <div class="slideshow-placeholder" :class="{active: forceActive}">
            <div class="slideshow-placeholder-content" v-on:click="$refs.imguplodad.click()">
              <h3>Upload image ↑</h3>
              <p>Images can be up to 5MB in size and either PNG, JPEG, JPG or BMP format</p>
            </div>
          </div>
        </div>

        <div class="carousel-item" v-for="(image, key) in section.file" v-bind:key="image.systemFileName"
             v-bind:class="{ active: (key === 0) && !card.editMode}">
          <a :href="VUE_APP_NOBB_API + 'File/' + image.systemFileName" target="_blank">
            <img alt="user-uploaded-image"
                 style=" vertical-align: middle; height: auto; border: 0; width: 100%; max-height: 531px; object-fit: scale-down"
                 v-bind:src="VUE_APP_NOBB_API + 'File/' + image.systemFileName"
            />
          </a>

          <div class="slideshow-delete-image-button" v-if="card.editMode">
            <a href="javascript:" v-on:click="deleteImage(image)" v-show="card.writePermissions & card.editMode"><i
                style="color: red">X</i> <i class="underline" style="z-index: 10000 !important;" :style="{color: card.textColor}">Delete image</i></a>
          </div>
        </div>
      </div>

      <!--       Left and right controls -->
      <button class="carousel-control-prev" type="button" data-bs-target="#slideshow" data-bs-slide="prev"
              v-if="section.file && section.file.length > 1 || card.editMode">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#slideshow" data-bs-slide="next"
              v-if="section.file && section.file.length > 1 || card.editMode">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>

    </div>
</template>

<script>
import CardService from '../../../services/CardService'
import EventBus from '../../../event-bus'
import JQuery from 'jquery'
window.$ = JQuery

export default {
  name: 'CardSlideshowComponent',
  props: ['idCardPage', 'idCard', 'card'],
  data () {
    return {
      forceActive: false,
      section: {
        idPageSection: false,
        value: '',
        position: 'SLIDESHOW',
        type: 'SLIDESHOW',
        file: [],
      },
      VUE_APP_NOBB_API: process.env.VUE_APP_NOBB_API
    }
  },
  methods: {
    getSection: function () {
      CardService.getSection(this.idCard, this.idCardPage, this.section.idPageSection).then((response) => {
        this.section = response.data

        return this.section
      })
    },
    handleFileUpload: function (event) {
      const upload = new FormData()
      upload.append('file', event.target.files[0])

      CardService.addImageToSection(this.idCard, this.idCardPage, this.section.idPageSection, upload).then((response) => {
        if (!this.section.file) {
          this.section.file = []
        }

        this.section.file.push(response.data)
      })
    },
    slideshowIndex (index) {
      if (this.card.editMode) {
        return index + 1
      } else {
        return index
      }
    },
    deleteImage (image) {
      CardService.deleteImageFromSection(this.card.idCard, this.idCardPage, this.section.idPageSection, image.idCardSectionFile).then(() => {
        // Delete the object from the array once deletion backend call has succeeded.
        document.getElementById('slideshowBackButton').click();
        this.section.file = this.section.file.filter(function (el) { return el.idCardSectionFile !== image.idCardSectionFile })
      })
    }
  },
  mounted () {
    CardService.getPageAndSections(this.idCard, this.idCardPage).then((response) => {
      this.found = false
      if (response.data.sections) {
        for (let section of response.data.sections) {
          if (section.position === this.section.position) {
            this.section = section
            this.found = true
          }
        }
      }

      if (!this.found) {
        CardService.addSectionToPage(this.idCard, this.idCardPage, this.section).then((response) => {
          this.section = response.data
          this.section.new = true
          // this.section.file = []
        })
      }

      CardService.getSection(this.idCard, this.idCardPage, this.section.idPageSection).then((response) => {
        this.section = response.data
      })
    })

    EventBus.$on('modeChange', mode => {
      this.$set(this.card, 'editMode', mode)
      this.$forceUpdate()
    })
  },
  computed: {
    images () {
      if (!this.section.file) {
        return [{
          systemFileName: 1
        }]
      }

      return this.section.file
    },
    user () {
      return this.$store.state.user
    }
  }
}
</script>

<style scoped>
    .img-txt-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-weight: lighter;
        font-size: 18px;
    }

    img.fixed-height {
      object-fit: contain;
        border: 1px solid transparent;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

</style>
