import Api from './Api'

export default {
  addNewCard (card) {
    return Api().post('/card', card)
  },
  togglePublishCard (card) {
    return Api().put('/card/' + card.idCard + '/publish')
  },
  deleteCard (card) {
    return Api().delete('/card/' + card.idCard)
  },
  updateCardInfo (card) {
    return Api().put('/card/' + card.idCard, card)
  },
  getCardInfo (idCard) {
    return Api().get('/card/' + idCard)
  },
  getAllCards(orderBy = 'lastEdited', direction = "desc", userOnly = false, category = null) {
    // Todo Implement pagination.
    return Api().get('/card', {
      params: {
        orderBy: orderBy,
        userOnly: userOnly,
        category: category,
        direction: direction
      }
    })
  },
  searchCards(search, orderBy = 'lastEdited', direction = 'DESC', category = null) {
    // Todo Implement pagination.
    return Api().get('/card?search=' + search, {
      params: {
        search: search,
        orderBy: orderBy,
        direction: direction,
        category: category
      }
    })
  },
  getPagesByCard (idCard) {
    return Api().get('/card/' + idCard + '/page')
  },
  addPageToCard (idCard, page) {
    return Api().post('/card/' + idCard + '/page', page)
  },
  getPageAndSections (idCard, idPage) {
    return Api().get('/card/' + idCard + '/page/' + idPage)
  },
  addSectionToPage (idCard, idPage, section) {
    return Api().post('/card/' + idCard + '/page/' + idPage + '/section', section)
  },
  updateSection (idCard, idPage, idSection, section) {
    return Api().put('/card/' + idCard + '/page/' + idPage + '/section/' + idSection, section)
  },
  getSection (idCard, idPage, idSection) {
    return Api().get('/card/' + idCard + '/page/' + idPage + '/section/' + idSection)
  },
  addImageToSection(idCard, idPage, idSection, data) {
    return Api().post('/card/' + idCard + '/page/' + idPage + '/section/' + idSection + '/image', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  deleteImageFromSection(idCard, idPage, idSection, idImage) {
    return Api().delete('/card/' + idCard + '/page/' + idPage + '/section/' + idSection + '/file/' + idImage)
  },
  getAllCardCategories() {
    return Api().get('/category')
  }
}
