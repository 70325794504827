<template>
  <div>
    <div class="container mt-4 card-container" id="cardGrid">
      <div class="row">
        <div class="d-flex justify-content-between" v-if="userOnly">
          <h4>Your Cards</h4>
          <a v-on:click="addCard()" href="#"><h4>+ Add card</h4></a>
        </div>
        <h4 v-if="!userOnly && user.loggedIn">All cards</h4>
        <hr v-if="user.loggedIn" class="hr-black-bold">

        <div class="d-flex justify-content-between" v-if="!userOnly"
             style="padding-bottom: 55px; max-width: 44% !important;">
          <div class="card-filter" :class="{'active': (this.filter === 'all')}" v-on:click="changeFilter('all')">All
            items
          </div>
          <div v-for="(item, index) in cardCategories" :key="index">
            <div class="card-filter" :class="{'active': (filter === item.idCategory)}"
                 v-on:click="changeFilter(item.idCategory)">{{ item.categoryName }}
            </div>
          </div>
        </div>
        <div v-if="!userOnly" class="sort-filter" style="text-align: right">
          <div class="dropdown">
            <a href="" id="filterCardsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              Sort
              <template v-if="orderByDirection==='DESC'">↓</template>
              <template v-if="orderByDirection==='ASC'">↑</template>
            </a>
            <ul class="dropdown-menu" aria-labelledby="filterCardsDropdown">
              <li><a class="dropdown-item" href="#" v-on:click="applyFilterOrderBy('title', 'ASC')"
                     :class="{'active' : (this.orderBy === 'title' && this.orderByDirection === 'ASC')}">A-Z</a></li>
              <li><a class="dropdown-item" href="#" v-on:click="applyFilterOrderBy('title', 'DESC')"
                     :class="{'active' : (this.orderBy === 'title' && this.orderByDirection === 'DESC')}">Z-A</a></li>
              <li><a class="dropdown-item" href="#" v-on:click="applyFilterOrderBy('lastEdited', 'DESC')"
                     :class="{'active' : (this.orderBy === 'lastEdited' && this.orderByDirection === 'DESC')}">Latest
                up</a></li>
              <li><a class="dropdown-item" href="#" v-on:click="applyFilterOrderBy('lastEdited', 'ASC')"
                     :class="{'active' : (this.orderBy === 'lastEdited' && this.orderByDirection === 'ASC')}">Latest
                down</a></li>
            </ul>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-auto mb-3">
          <a href="#"
             v-on:click="addCard()">
            <div class="card">
              <div class="card-body add-card">
                +&nbsp;<u>Add New Card</u>
              </div>
            </div>
          </a>
        </div>

        <div class="col-auto mb-3" v-for="card in cards" :key="card.idCard">
          <a href="#" data-bs-toggle="modal" :data-bs-target="'#cardModal' + uid"
             v-on:click="loadCard(card, false, true)">
            <div class="card" :style="{'background': card.cardColor}"
                 :class="{'lighten-card': !card.published, 'cardBorder': card.border}"
                 v-if="!userOnly || userOnly && user.company.idCompany === card.cardCompany.idCompany">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 margin-bottom">
                    <div class="card-text text-uppercase special2" :style="{color: card.textColor}">
                      {{ card.title }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-text special">
                      <div class="keep-bottom" :style="{color: card.textColor}">
                        {{ card.cardCompany.companyName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div v-if="userOnly" style="padding-bottom: 155px"></div>

      </div>
    </div>
    <!--End card-grid-->

    <AlertComponent type="nobb-alert-primary" message="Thank you, that you are into creating fancy cards.
        To do so, please register or login, so that we can safe your cards and you will never loose them!"
                    v-if="loginAlert"></AlertComponent>

    <!-- Start card modal render !-->
    <div class="modal fade" :id="'cardModal' + uid" tabindex="-1" role="dialog" aria-hidden="true" ref="cardModal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="w-12 float-right"
             style="margin-left: auto; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
          <div class="close-modal" style="color: white;">
            Close X
          </div>
        </div>

        <div class="w-12 d-flex align-items-center justify-content-center cardPinStripe"
             :style="'background: ' + cardEdit.cardColor">
        </div>

        <div class="modal-content" v-show="(cardEdit.new && !user.loggedIn)">
          <div class="modal-header">
            <div class="view-card-logo">
              <div class="logo-container">
                <h2>Oh no!</h2>
              </div>
            </div>

            <div class="modal-dismiss-container">
              <button type="button" class="close-override" data-bs-dismiss="modal" aria-label="close">
                <span aria-hidden="true" class="modal-dismiss">X</span>
              </button>
              <a class="small-subtext" style="color: black !important;" data-bs-dismiss="modal">close</a>
            </div>
          </div>
        </div>
        <div class="modal-content" :style="'background: white;'"
             v-show="!(cardEdit.new && !user.loggedIn)"
             style="background-blend-mode: screen;">
          <div class="pre-header" v-if="cardEdit.editMode" :style="{color: cardEdit.textColor}">
            <b :style="{color: cardEdit.textColor}"> Category</b> &nbsp; Which category does your card best fit into?
          </div>
          <div class="modal-header">
            <div class="category-selector d-flex justify-content-between">
              <div style="margin-left: 10px;"></div>

              <template v-for="(item, index) in cardCategories">
                <div class="category-item" :key="index" v-on:click="updateCategory(item.idCategory)"
                     :class="{'active': (cardEdit.cardCategory === item.idCategory)}"
                     :style="[cardEdit.cardCategory === item.idCategory ? {borderColor: cardEdit.textColor, color: cardEdit.textColor} : {}]"
                     v-show="(cardEdit.cardCategory === item.idCategory) || cardEdit.editMode">{{ item.categoryName }}
                </div>
              </template>
            </div>


            <div class="modal-undo-container d-flex justify-content-between card-menubar">
              <template>

                <template v-if="cardEdit.editMode && cardEdit.writePermissions">
                  <div class="modal-undo-container-item">
                    <a href="#" v-on:click="deleteCardAlert=true" :style="{color: cardEdit.textColor}">Delete card</a>
                  </div>
                  <div class="modal-undo-container-item">
                    <button class="btn btn-primary-nextgen" v-b-tooltip.click
                            :title="getTitle()" triggers="click" v-on:click="togglePublishCard">
                      <template v-if="cardEdit.published">Unpublish</template>
                      <template v-if="!cardEdit.published">Publish</template>
                      card
                    </button>
                  </div>
                </template>

                <template v-if="!cardEdit.editMode || !cardEdit.writePermissions">
                  <template v-if="cardEdit.writePermissions">
                    <div class="modal-undo-container-item" :style="{color: cardEdit.textColor}">
                      Connect ↗
                    </div>
                  </template>

                  <div class="modal-undo-container-item">
                    <a href="#" v-on:click="loadShareAlert" :style="{color: cardEdit.textColor}">Share ↗</a>
                  </div>


                </template>

                <template v-if="cardEdit.writePermissions">
                  <div class="modal-undo-container-item d-flex justify-content-between">
                    <div style="padding-right: 10px;" :style="{color: cardEdit.textColor}">Edit</div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                             v-model="cardEdit.editMode" v-on:click="toggleEditMode">
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div class="suf-header" v-if="cardEdit.editMode" :style="{color: cardEdit.textColor}">
            <b :style="{color: cardEdit.textColor}">Title</b> &nbsp; Which title makes your way or mean for innovation
            easy to find?
          </div>


          <div class="modal-body" v-if="cardEdit.idCard">

            <FirstPage :card="cardEdit" v-show="currentPageNumber === 1" @updateCard="updateCardInfo"
                       :next-card="nextCardInfo('next')" :previous-card="nextCardInfo('previous')"></FirstPage>

            <div class="row justify-content-center">
              <div class="col-md-6 max-width-660">

                <hr class="hr-card-divider"/>
                <div class="connected-card-controller d-flex justify-content-between">
                  <b>Connected Cards ({{ cards.length }})</b>
                  <b>+ Connect Card</b>
                </div>
                <hr class="hr-card-divider"/>
                <a href="#" v-on:click="previousLinkedCardPage()" v-if="previousLinkedCardPage(true)">←</a> Page
                {{ this.linkedCardHelper.page }}/{{ Math.round(cards.length / this.linkedCardHelper.cardPerPage) }} <a
                  href="#" v-on:click="nextLinkedCardPage()" v-if="nextLinkedCardPage(true)">→</a>

              </div>
            </div>

            <div class="row justify-content-center" style="justify-content: center !important;">
              <div class="col-md-6 max-width-660" style="width: 100%;">
                <div class="mt-4 card-container" id="connectedCardGrid" v-touch:swipe="linkedCardsSwipeHandler">
                  <div class="row">

                    <!-- Filter by when Linked on top -->
                    <div class="col-auto mb-3" v-for="card in cards.slice(linkedCardHelper.start,linkedCardHelper.end)"
                         :key="card.idCard">
                      <a href="#" data-bs-toggle="modal" :data-bs-target="'#cardModal' + uid"
                         v-on:click="loadCard(card, false, true)">
                        <div class="card connectedCard" :style="{'background': card.cardColor}"
                             :class="{'lighten-card': !card.published, 'cardBorder': card.border}"
                             v-if="!userOnly || userOnly && user.company.idCompany === card.cardCompany.idCompany">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12 margin-bottom">
                                <div class="card-text text-uppercase special2" :style="{color: card.textColor}">
                                  {{ card.title }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="card-footer">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="card-text special">
                                  <div class="keep-bottom" :style="{color: card.textColor}">
                                    {{ card.cardCompany.companyName }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Maximum of 2 cards width. A swipe gesture shall browse through the cards.                         -->

                          <div class="link-card-info">
                            <div class="link-card-metadata">
                              <div class="link-card-metadata-item">
                                <b>Connected By</b> <br>
                                Author A
                              </div>

                              <div class="link-card-metadata-item">
                                <b>Connected since</b> <br>
                                A few seconds ago
                              </div>
                            </div>

                            <div class="link-card-description">
                              <b>Comment</b> <br>
                              This is my comment on this card, based on a connection with this card and the one selected
                              by me from my own cards. This comment can be as long as I think that it would be necessary
                              for me to make my point on why this connection makes sense.
                            </div>
                          </div>


                        </div>
                      </a>
                    </div>
                    <div v-if="userOnly" style="padding-bottom: 155px"></div>

                  </div>
                </div>
              </div>
              <ShareAlertComponent type="nobb-alert-primary" class="text-uppercase"
                                   message="Share this card now via mail or by copying this sharing-link"
                                   :link="'mailto:?body=' + emailBody" v-if="shareAlert"></ShareAlertComponent>
              <AlertComponent :message="this.errorAlert.message" type="nobb-alert-warning"
                              v-if="this.errorAlert.shown"></AlertComponent>

              <decision-alert-component type="nobb-alert-primary" ack-message="Yes delete this card"
                                        reject-message="No do not delete this card"
                                        message="Are you sure you want to delete this card? " v-if="deleteCardAlert"
                                        @acknowledged="deleteCard(cardEdit)"
                                        @rejected="deleteCardAlert=false"></decision-alert-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End card Modal render !-->
  </div>
</template>

<script>
import FirstPage from './FirstPage'
import CardService from '../../services/CardService'
import ShareAlertComponent from '../alert/ShareAlertComponent'
import EventBus from '../../event-bus.js'
import _ from 'lodash'
import AlertComponent from '../alert/AlertComponent'
import DecisionAlertComponent from '../alert/DecisionAlertComponent'
import {Modal} from 'bootstrap'
import router from '../../router'

export default {
  name: 'CardViewerComponent',
  components: {DecisionAlertComponent, AlertComponent, FirstPage, ShareAlertComponent},
  props: ['userOnly'],
  computed: {
    user() {
      return this.$store.state.user
    },
    orderedPages: function () {
      return _.orderBy(this.cardEdit.pages, 'pageNumber')
    }
  },
  mounted() {

    // If direct linking, open card.
    if (this.$route.params.idCard && !this.userOnly) {
      console.log('Trying to open card with id: ' + this.$route.params.idCard)

      let openCard = []

      openCard['idCard'] = this.$route.params.idCard

      this.loadCard(openCard, true)
    }

    this.uid = Math.random().toString(36).substring(7)

    EventBus.$on('search-card', resp => {
      this.currentSearchQuery = resp

      CardService.searchCards(resp, this.orderBy).then((response) => {
        let cards = response.data

        this.setCardColors(cards)
        this.cards = cards

        if (location.hash === '#' || location.hash !== '#/about') {
          location.hash = '#' + 'cardGrid'
        }
      })

      this.setCardColors()

      EventBus.$on('cardsUpdated', () => {
        this.cards = false
        console.log('Event received from CardViewerComponent!')
      })
    })

    EventBus.$on('cards-refresh', () => {
      this.loadAllCards()
    })

    EventBus.$on('loadCard', (data) => {
      this.loadCard(data, false, true)
    })

    this.cardEdit_copy = Object.assign(this.cardEdit)

    EventBus.$on('orderBy', resp => {
      this.orderBy = resp[0]
      this.orderByDirection = resp[1]

      console.log('Current search query: ' + this.currentSearchQuery)
      console.log("orderBy: " + this.orderBy + 'direction: ' + this.direction);

      if ((this.currentSearchQuery === '' || this.currentSearchQuery === undefined)) {
        this.loadAllCards()
      } else {
        CardService.searchCards(this.currentSearchQuery, this.orderBy, this.orderByDirection, this.filter).then((response) => {
          let cards = response.data

          this.setCardColors(cards)
          this.cards = cards
        })
      }
    })

    this.loadAllCards()

    CardService.getAllCardCategories().then((response) => {
      this.cardCategories = response.data
    })
  },
  methods: {
    async updateCategory(category) {
      this.cardEdit.cardCategory = category
      await this.updateCardInfo(this.cardEdit)
    },
    changeFilter(filter) {
      this.filter = filter

      EventBus.$emit("clearSearch")

      CardService.getAllCards('lastEdited', this.orderByDirection, this.userOnly, this.filter).then((response) => {
        this.cards = response.data

        this.setCardColors(this.cards)
      })


    },
    getFilterState() {
      return this.filter;
    },
    loadShareAlert: function () {
      this.shareAlert = true
    },
    cardsUpdated() {
      console.log('Received an event')
    },
    loadErrorAlert: function (message) {
      this.errorAlert.shown = true
      this.errorAlert.message = message
    },
    setCardColors: function (cards) {
      for (let i = 0; i < cards.length; i++) {
        cards[i].textColor = (parseInt(cards[i].cardColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff'
        if (cards[i].cardColor === '#ffffff') {
          cards[i].border = true
        }
      }
    },
    addSource: function (newSource) {
      let source = {
        text: newSource
      }

      this.cardEdit.sources.push(source)

      this.cardEdit.addSource = ''
    },
    loadPage: function (page) {
      this.cardEdit.currentPage = page
      this.currentPageNumber = page
    },
    getSectionIndex(item, position) {
      return item.findIndex(i => i.position === position)
    },
    azOrder: function () {
      EventBus.$emit('orderBy', ['title', this.orderByDirection])
      this.orderBy = 'title'

      // eslint-disable-next-line no-empty
      if (this.orderByDirection === "DESC") {
        this.orderByDirection = "ASC"
      } else {
        console.log("Flipping order by direction")
        this.orderByDirection = "DESC"
        console.log(this.orderByDirection)
      }
    },
    applyFilterOrderBy: function (by, direction) {
      console.log('Ordering by: ' + by + ' and direction: ' + direction);
      EventBus.$emit('orderBy', [by, direction])
      direction = ""
    },
    addCard() {
      if (this.user.loggedIn) {
        this.cardEdit = Object.assign(this.cardEdit_copy)
        this.cardEdit.cardColor = this.user.company.companyColor
        this.cardEdit.cardCompany = this.user.company
        this.cardEdit.cardType = 'DEFAULT'
        this.cardEdit.published = false
        this.cardEdit.new = true

        CardService.addNewCard(this.cardEdit)
            .then((response) => {
              this.cardEdit = response.data
              this.cardEdit.writePermissions = true
              this.cardEdit.pages = []
              this.cardEdit.published = false
              // this.cardEdit.textColor = (parseInt(this.cardEdit.cardColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff'
              this.cardEdit.textColor = '#000'
              this.cardEdit.currentPage = 1 // Default to first page on add.
              this.currentPageNumber = 1 // Always default to the first page, when loading new cards.
              this.cardEdit.editMode = true

              this.cardPush = this.cardEdit

              // lastEditedBy
              this.cardPush.lastEditedBy = this.cardEdit['lastEditedBy']['idUser']

              this.cards.push(this.cardPush)
              this.$forceUpdate()

              let options = {
                focus: true
              }

              let cardEditModal = new Modal(document.getElementById(this.$refs['cardModal'].id), options)
              cardEditModal.show()
              EventBus.$emit('cardsUpdated', true)

              this.$router.push({path: '/card/' + this.cardEdit.idCard})
              location.reload()

            }).catch((error) => {
          console.error('Error' + error)
        })
      } else {
        this.loginAlert = true
      }
    },
    loadAllCards: function () {
      CardService.getAllCards(this.orderBy, this.orderByDirection, this.userOnly, this.filter).then((response) => {
        this.cards = response.data

        if (!this.userOnly) {
          this.cards = this.cards.filter(card => (card.cardOwner === this.user.idUser && card.published === 1) || (card.cardOwner !== this.user.idUser)) // Use numerical true/false due to php REST issues
        }

        if (this.userOnly) {
          this.cards = this.cards.filter(card => card.cardOwner === this.user.idUser)
        }

        this.setCardColors(this.cards)
      })
    },
    loadCard: function (card, openModal = false, setRoute = false) {
      // Forcing the components to disappear before reinitializing again.
      this.shareAlert = false
      this.cardEdit = false
      this.currentPageNumber = 1 // Always default to the first page, when loading new cards.
      this.publishSure = false

      CardService.getCardInfo(card.idCard).then((response) => {
        this.cardEdit = response.data
        this.undo_card = response.data

        this.cardEdit.writePermissions = false
        this.cardEdit.editMode = false
        // this.cardEdit.textColor = (parseInt(this.cardEdit.cardColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff'
        this.cardEdit.textColor = '#000'
        this.cardEdit.published = +response.data.published

        if (this.user.company.idCompany === response.data.cardCompany.idCompany && !this.user.isSuspended) {
          this.cardEdit.writePermissions = true
        } else {
          // If the user owns the card, then he always should have writePermissions.
          if (this.cardEdit.lastEditedBy.idUser === this.user.idUser) {
            this.cardEdit.writePermissions = true
          }
        }

        console.log(this.cardEdit)

        // Auto enable editMode on unpublished cards
        if (this.cardEdit.writePermissions && this.cardEdit.published === 0) {
          this.toggleEditMode()
        }

        if (openModal) {
          let options = {
            backdrop: true,
            keyboard: true,
            focus: true
          }

          let cardEditModal = new Modal(document.getElementById(this.$refs['cardModal'].id), options)
          cardEditModal.show()
        }

        if (setRoute) {
          router.push({name: 'CardLinkingComponent', params: {idCard: card.idCard}}).catch(() => {
          })
        }

        this.linkedCardHelper.start = 0
        this.linkedCardHelper.end = this.linkedCardHelper.cardPerPage
      })
    },
    nextCardInfo: function (direction = "next") {
      let direction_integer;
      if (direction === "next") {
        direction_integer = 1
      } else {
        direction_integer = -1
      }

      let currentCardIndex;
      currentCardIndex = this.cards.findIndex(card => card.idCard === this.cardEdit.idCard)
      const newCard = this.cards[currentCardIndex + direction_integer];

      return {
        available: typeof newCard !== "undefined",
        newCard: newCard,
      }

    },
    nextCard: function (direction = "next", cardAvailableCheck = false) {
      let direction_integer;
      if (direction === "next") {
        direction_integer = 1
      } else {
        direction_integer = -1
      }

      let currentCardIndex;
      currentCardIndex = this.cards.findIndex(card => card.idCard === this.cardEdit.idCard)

      const newCard = this.cards[currentCardIndex + direction_integer];

      if (typeof newCard !== "undefined" && !cardAvailableCheck) {
        let cardEditModal = Modal.getInstance(document.getElementById(this.$refs['cardModal'].id))
        cardEditModal.hide()

        this.loadCard(newCard, true, true)
      }

      if (cardAvailableCheck) {
        return typeof newCard !== "undefined"
      }

    },
    deleteCard: function (card) {
      CardService.deleteCard(card).then(() => {
        let cardEditModal = Modal.getInstance(document.getElementById(this.$refs['cardModal'].id))
        cardEditModal.hide()

        EventBus.$emit('cardsUpdated', true)
        EventBus.$emit('cards-refresh', true)
      })
    },
    updateCardInfo: function (card) {
      CardService.updateCardInfo(card).then((response) => {

        card = this.cards.find(x => x.idCard === response.data.idCard)
        card.title = response.data.title
        card.lastEdited = response.data.lastEdited
        card.lastEditedBy = response.data.lastEditedBy
        card.published = response.data.published
        card.cardColor = response.data.cardColor

        if (card.editMode) {
          EventBus.$emit('cardsUpdated', true)
          EventBus.$emit('cards-refresh', true)
        }
      })
    },
    togglePublishCard: function () {
      if (!this.publishSure) {
        this.publishSure = true
      } else {
        this.publishSure = false
        CardService.togglePublishCard(this.cardEdit).then(() => {
          this.cardEdit.published = !this.cardEdit.published
          let card = this.cards.find(x => x.idCard === this.cardEdit.idCard)
          card.published = this.cardEdit.published
          EventBus.$emit('cards-refresh', true)
        }).catch((error) => {
          console.error(error.response.data.message)
          this.loadErrorAlert(error.response.data.message)
        })
      }
    },
    toggleEditMode: function () {
      this.$set(this.cardEdit, 'editMode', !this.cardEdit.editMode)

      EventBus.$emit('modeChange', this.cardEdit.editMode)
      this.$forceUpdate()
    },
    getTitle() {
      if (this.cardEdit.published) {
        return 'Clicking this button again will hide your great work from the whole world, are you sure ?'
      }

      return 'Clicking this button again will really publish this card making it available to the whole world, are you sure? '
    },
    nextLinkedCardPage(validationOnly = false) {
      if (validationOnly) {
        return this.linkedCardHelper.end < this.cards.length
      }
      this.linkedCardHelper.start += this.linkedCardHelper.cardPerPage
      this.linkedCardHelper.end += this.linkedCardHelper.cardPerPage
      this.linkedCardHelper.page += 1
    },
    previousLinkedCardPage(validationOnly = false) {
      if (validationOnly) {
        return this.linkedCardHelper.start > 0
      }

      this.linkedCardHelper.start -= this.linkedCardHelper.cardPerPage
      this.linkedCardHelper.end -= this.linkedCardHelper.cardPerPage
      this.linkedCardHelper.page -= 1
    },
    linkedCardsSwipeHandler(direction) {
      console.log('Handler!' + direction)

      if (direction === 'right' && this.previousLinkedCardPage(true)) {
        this.previousLinkedCardPage()
      } else if (direction === 'left' && this.nextLinkedCardPage(true)) {
        this.nextLinkedCardPage()
      }


    }
  },
  data() {
    return {
      uid: '',
      hideSourceAdd: false,
      cards: [],
      cardEdit_copy: false,
      undo_card: {},
      shareAlert: false,
      errorAlert: {message: '', shown: false},
      loginAlert: false,
      deleteCardAlert: false,
      filter: 'all',
      orderByDirection: 'DESC',
      orderBy: 'lastEdited',
      cardCategories: [],
      sortDirection: "DESC",
      linkedCardHelper: {
        start: 0,
        end: 0,
        page: 1,
        cardPerPage: 2
      },
      cardEdit: {
        idCard: '',
        title: 'THE MEANS\' CATCHY TITLE!',
        lastEdited: '',
        lastEditedBy: '',
        cardColor: '',
        cardCompany: '',
        cardType: 'DEFAULT',
        published: false,
        writePermissions: true,
        editMode: false,
        currentPage: 1,
        textColor: '#000',
        orderBy: 'lastEdited',
        pages: [{
          'idCard': 5,
          'idCardPage': 1,
          'pageNumber': 1,
          'title': ''
        },
          {
            'idCardPage': 2,
            'idCard': 6,
            'pageNumber': 2,
            'title': ''
          }]
      },
      VUE_APP_NOBB_API: process.env.VUE_APP_NOBB_API,
      currentPageNumber: 1,
      searchQuery: '',
      publishSure: false,
      emailBody: 'Hi, %0D%0A' +
          'maybe that\'s the inspiration you needed?! %0D%0A' +
          'Check this awesome card on innovation on nobb.io: ' +
          window.location.href + ' %0D%0A' +
          'nobb.io – what is it?! %0D%0A' +
          '"How does innovation come to life?" %0D%0A' +
          'Driven by the curiosity to find answers to this question, we created nobb.io. %0D%0A' +
          'It is a platform for sharing experience, ideas, methods, strategies and ways and means for innovation among like-minded innovators. %0D%0A' +
          'Learn more on nobb.io %0D%0A' +
          'HAPPY SHARING %0D%0A' +
          'best, %0D%0A' +
          'the nobbs',
    }
  },
  watch: {
    user: function () {
      this.loadAllCards()
      console.info('Refreshing card render since user state changed. ')
    }
  }
}
</script>
<style>

textarea:disabled {
  color: black;
}

button:disabled {
  background-color: #707070 !important;
  border-color: #707070 !important;
  cursor: not-allowed !important;
}

.mobile-hr-divider {
  margin-left: 0;
  width: 10%;
  color: black;
  height: 1px;
  background-color: black;
}

/* Custom, non global component styling. */
.textarea-spacer {
  min-height: 2.0vw;
}

.add-source-form {
  background: none;
  border: none;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 10pt;
}

.publish-btn {
  position: absolute;
  width: 178px;
  height: 46px;
  top: 100%;
  left: 40%;
  font-size: 25px;
}

.share-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 100%;
  left: -100px;
  font-size: 14px;
  border-radius: 50px;
  transform: none !important;
  font-style: italic;
}

.delete-card {
  z-index: -1000;
  /*width: 178px;*/
  /*height: 46px;*/
  position: absolute;
  top: 110%;
  left: 45%;
}

.logo-centered {
  float: left;
  max-height: 60px;
  margin: 10px;
}

.logo-container {
  position: absolute;
  /*background: white;*/
  /*border-radius: 2px;*/
  border: none;
  margin-top: -16px !important;
}

.small-subtext {
  font-size: 10pt;
  color: white !important;
  z-index: 0 !important;
}

span:hover {
  cursor: pointer;
}

.cardAuthor {
  position: absolute;
}

.modal-dismiss {
  font-size: 2.5em;
}
</style>


